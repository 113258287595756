<template>
  <div class="roleTab">
    <div class="mainform">
      <div class="form mg-form">
        <div class="el-form">
          <div class="check-num">
            <span>需求数量：</span>
            <span>{{dialogData.demandNumber}}</span>
            <span style="margin-left:20px">待响应数量：</span>
            <span>{{dialogData.remainNumber}}</span>
          </div>
          <el-table class="table" stripe :data="selectTabData" style="width: 100%" @selection-change="SelectionChange">
            <el-table-column type="selection" width="55" :selectable="canselect"></el-table-column>
            <el-table-column label="序号" width="60px">
              <template #default="scope">
                {{scope.$index+1}}
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" label="是否锁定" width="80px">
              <template #default="scope">
                {{scope.row.isLock?'是':!scope.row.isLock?'否':scope.row.isLock}}
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="orderRowCode" label="采购单编号/行号" width="160px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="sellCode" label="销售订单号" width="120px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="sellRow" label="销售订单行号" width="120px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="demandDate" label="PO交期" width="100px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="LGORT" label="BU事业部标识" width="120px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="companyCode" label="工厂" width="70px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="BSTRF" label="最小送货数量" width="110px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="remainNumber" label="未交数量" width="120px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="number" label="送货数量" width="120px">
              <template #default="scope">
                <el-input v-model="scope.row.number" @input="(val)=>{scope.row.number = val.replace(/\D|^[0]+/g,'')}" placeholder="请输入"></el-input>
                <!-- <span v-else>{{scope.row.number}}</span> -->
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="BEDNR" label="需求跟踪号" width="100px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="ZTEXT" label="项目专用" width="120px"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
export default {
  components: Component.components,
  name: 'AddPurchaseNumBody',
  props: ['tabData', 'dialogData', 'sellCodeStart'],
  data () {
    return {
      selectTabData: [],
      newSelectTabData: []
    }
  },
  created () {
    this.selectTabData = this.dialogData.orderData
    console.log(this.selectTabData,22)
  },
  mounted () {

  },
  methods: {
    canselect (row, index) {
      return !row.isLock
    },
    // 获取选中的订单
    SelectionChange (data) {
      this.newSelectTabData = data
      // this.newSelectTabData.forEach(item => {
      //   item.number = ''
      // })
    }
  },
  watch: {
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  .tableNav{
    border: none;
  }
  .roleTab{
    .mg-form {
      padding: 0 10px;
      .el-table th {
        padding: 9px 0;
        background: #ededed;
        font-size: 14px;
      }
      :deep(.el-table__body td){
        padding: 2px 0;
      }
      .el-input__inner{
        height: 32px;
        line-height: 32px;
      }
    }
    .check-num{
      margin-bottom: 10px;
      color: #666;
    }
  }
  .tableList{
    padding: 0 20px;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 250px;
  }
  .roleTab .tableNav :deep(.searchList .col .title){
    min-width: 42px;
  }
</style>
